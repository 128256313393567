import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as DataPointStore from '../store/DataPoints';
import DataPage from './DataPoints/DataPoints.js';
import * as Login from '../store/Login';

/**
 * Receives props/state from DataPoints.ts
 * */

type DataPointProps =
    DataPointStore.DataPointState
    & typeof DataPointStore.actionCreators
class CurrentDataPoints extends React.PureComponent<DataPointProps> {

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    
    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        let user = Login.actionCreators.getUser();

        this.props.requestData("current", user.interconnect);
        this.props.refreshData("current");
    }

    public render() {

        return (
            <React.Fragment>
                <DataPage source={Object.assign({ data: this.props.currentData, isLoading: this.props.currentDataLoading, filterLists: this.props.currentFilterLists, type: 'current', refreshDate: this.props.currentRefreshDate, from: 'currentData' })}
                    {...this.props} />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.dataPoints,
    DataPointStore.actionCreators
)(CurrentDataPoints as any);