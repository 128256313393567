import { useState } from 'react';
import { Grid } from '@mui/material';
import Popup from '../controls/Popup';
import UserForm from './UserForm';
import useTable from '../common/Table.js'
import Notify from '../common/Notify'

/**
 * Called from Header.js and calls UserForm.js to display user profile info.
 * Can also be called from Users.tsx by SPP users and lists all ICCPST user data
 * Communicates with Login.ts to update a user's information as they make changes in UserForm.js
 * @param {any} props
 */

const headCells = [
    {
        id: 'userName',
        label: 'Username'
    },
    {
        id: 'fullName',
        label: 'Full name'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'phone',
        label: 'Phone'
    },
    {
        id: 'company',
        label: 'Company'
    },
    {
        id: 'subscribeToEmail',
        label: 'Subscribed to Email'
    },
    {
        id: 'companyEmailList',
        label: 'Company Email Subscriptions'
    },
    {
        id: 'isDeleted',
        label: 'Account Deactivated'
    },
    {
        id: 'lastLogin',
        label: 'Last Login'
    },
    {
        id: 'createdOn',
        label: 'Created On'
    },
    {
        id: 'updatedOn',
        label: 'Updated On'
    }
]

const Users = (props) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [user, setUser] = useState({});

    const finalize = () => {
        setOpenPopup(false);
        setUser({});
    }

    const edit = (user, resetForm) => {
        // calls the updateUser method in Login.ts
        // pass either resetForm if view is 'single' (i.e. from Header.js user profile area)
        // or finalize function defined below on Users.js
        // pass callback to resetForm from UserForm.js, which is passed from useForm.js
        if (props.view === 'single')
            props.updateUser(user, props.view, resetForm);
        else
            props.updateUser(user, props.view, finalize);
        
    }

    const actions = [
        {
            icon: 'edit',
            tooltip: 'Edit the selected user',
            position: 'row',
            onClick: (event, rowData) => {
                // Check that the user performing this action is an admin if they're attempting to modify another admin user
                if (rowData.isAdmin && !props.user.isAdmin) {
                    Notify({ severity: 'error', message: 'You may not modify an admin user.' });
                }
                else {
                    setUser(rowData);
                    setOpenPopup(true);
                }
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh data',
            isFreeAction: true,
            // Calls the refreshData method in Login.ts
            onClick: () => {
                props.refreshData(true);
            }
        },
    ]

    const tableOptions = {
        filtering: true,
        selection: false,
        draggable: false,
        columnResizable: true,
        hideFilterIcons: true,
        search: true
    };

    const {
        MtlTable
    } = useTable(props.source, headCells, actions, tableOptions, null, false);

    // UserForm display returned to viewing user. Passes to UserForm the current user's information and also the edit function defined above.
    return (
        <>
            {props.view === 'single' ?
                <UserForm
                    user={props.user}
                    edit={edit}
                />
                :
                (props.user.isAdmin || props.user.isModeler) ?
                    <Grid container>
                        <Grid item xs={12}>
                            <MtlTable />
                        </Grid>
                    </Grid>
                    :
                    <Grid>
                        You are not authorized to view this data.
                    </Grid>
            }
            <Popup title='User Profile'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                finalize={finalize}
            >
                <UserForm
                    user={user}
                    edit={edit}
                />
            </Popup>
        </>
        )
}

export default Users