import Input from './Input'
import Select from './Select'
import Checkbox from './Checkbox'
import Button from './Button'
import ActionButton from './ActionButton'

const Controls = {
    Input,
    Select,
    Checkbox,
    Button,
    ActionButton
}

export default Controls;