import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import Notify from '../components/common/Notify';
import jwtDecode, { JwtPayload } from 'jwt-decode';

/**
 * This ts file handles all API calls to/from AuthController.cs and UserController.cs
 * */

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoginState {
    user: User;
    users: User[];
    isAuthenticated: boolean;
    loginFailed: boolean;
    message: string;
    isLoading: boolean;
    isLoaded: boolean;
    refresh: boolean;
}

export interface User {
    //id: string;
    userName: string;
    fullName: string;
    email: string;
    phone: string;
    subscribeToEmail: boolean;
    company: string;
    isAdmin: boolean;
    isModeler: boolean;
    isMpOnly: boolean;
    interconnect: string;
    token: string;
    companyEmailList: [];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestLoginAction {
    type: 'REQUEST_LOGIN';
    message: string;
}

interface RequestUserAction {
    type: 'REQUEST_USER';
    user: User;
    message: string
}

interface CheckLoginStatus {
    type: 'CHECK_LOGIN'
    isLoggedIn: boolean;
}

interface RequestUsersAction {
    type: 'REQUEST_USERS'
}

interface ReceiveUsersAction {
    type: 'RECEIVE_USERS'
    users: User[];
    message: string
}

interface RefreshUsersAction {
    type: 'REFRESH_USERS'
}

interface UpdateUserAction {
    type: 'UPDATE_USER';
    user: User;
    message: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestLoginAction | RequestUserAction | CheckLoginStatus | RequestUsersAction | ReceiveUsersAction | RefreshUsersAction | UpdateUserAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// All of the below fetch API calls go to the AuthController.cs and UserController.cs files
export const actionCreators = {
    login: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        // Only try to get user data from backend code if the Login ApplicationState has been initialized
        // and the user is not already authenticated

        // this works by sending the text included in fetch(...) below to the Controllers folder
        // the text in fetch(...) is matched to a route identified in the Controller cs files
        // and the result of method executed per the match is returned in the response below
        if (appState && appState.login && !appState.login.isAuthenticated && !appState.login.loginFailed) {
            fetch(`api/auth/login`)
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'REQUEST_LOGIN', message: data.message});
                                Notify({ severity: 'success', message: data.message })
                                // pass username received in data to back-end as UserDto username
                                fetch(`api/user/get`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        UserName: data.user.userName
                                    }),
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    }
                                })
                                    .then(response => {
                                        if (response.status === 200) {
                                            (response.json())
                                                .then(data => {
                                                    localStorage.setItem('userToken', JSON.stringify(data.user));
                                                    dispatch({ type: 'REQUEST_USER', user: data.user, message: data.message });
                                                    Notify({ severity: 'success', message: data.message })
                                                })
                                        }
                                        else {
                                            (response.json())
                                                .then(data => {
                                                    dispatch({ type: 'REQUEST_USER', user: data.user, message: data.message });
                                                    Notify({ severity: 'error', message: data.message })
                                                })
                                        }
                                    })
                            })
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'REQUEST_LOGIN', message: data.message });
                                Notify({ severity: 'error', message: data.message })
                            })
                    }
                })
        }
    },

    // update's the current user, or the provided user based on the source
    // if the source is 'single' then the user info came from the current user's profile view
    // if the source is 'list' then the user info came from the User page and may have been modified by someone else
    updateUser: (user: User, source: string, finalize?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.login && appState.login.user) {
            let appStateUser = appState.login.user
            fetch(`api/user/update`, {
                method: 'POST',
                body: JSON.stringify({
                    UserName: user.userName,
                    FullName: user.fullName,
                    Email: user.email,
                    Phone: user.phone,
                    SubscribeToEmail: user.subscribeToEmail,
                    Company: user.company,
                    Interconnect: user.interconnect,
                    CompanyEmailList: user.companyEmailList,
                    Token: user.token
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                // Check source of data or if the user being updated is the current user. If so, update the current user
                                if (source === 'single' || appStateUser.userName === user.userName) {
                                    localStorage.setItem('userToken', JSON.stringify(data.user));
                                    dispatch({ type: 'UPDATE_USER', user: data.user, message: data.message });
                                }
                                // Otherwise keep the user from state since the user calling this action is calling it
                                // from the Users page (only visible to SPP users) and has updated a different user than themselves.
                                if (source === 'list') {
                                    // Keep user as state user if the user they're updating isn't them.
                                    if (!(appStateUser.userName === user.userName))
                                        dispatch({ type: 'UPDATE_USER', user: appStateUser, message: data.message });
                                    // use callback passed from Users.js to close/reset windows
                                    finalize();
                                }

                                
                                Notify({ severity:'success', message: data.message })
                            })
                    }
                })
        }
    },

    refreshData: (forceRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        const appState = getState();

        if ((appState && appState.login && appState.login.refresh) || forceRefresh) {
            Notify({ severity: 'info', message: 'Refreshing data' });
            dispatch({ type: 'REFRESH_USERS' });
        }
    },

    // checks if the user is already logged in and whether or not their token is expired
    isLoggedIn: () => {
        // check if the user uuid token exists in current browser session
        let token = actionCreators.getToken("userToken");
        // if token exists, verify it hasn't expired
        if (token !== "" && token !== null) {
            let decodedToken = jwtDecode<JwtPayload>(token.token)

            if(decodedToken !== undefined && decodedToken !== null && !actionCreators.isTokenExpired(decodedToken))
                return true;
        }
        else
            return false;
    },

    // confirms the user is already logged in and has a valid token before parsing the user information and returning it
    getUser: () => {
        if (actionCreators.isLoggedIn()) {
            let user = actionCreators.getToken("userToken");
            return user;
        }
    },

    // Retrieves a list of all users in ICCPST and returns the result
    getUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.login && appState.login.user && !appState.login.isLoading && !appState.login.isLoaded) {
            fetch(`api/user/getAll`)
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'RECEIVE_USERS', users: data.users, message: data.message });
                                Notify({ severity: 'success', message: data.message });
                            })
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'RECEIVE_USERS', users: data.users, message: data.message });
                                Notify({ severity: 'error', message: data.message })
                            })
                    }
                });
            dispatch({ type: 'REQUEST_USERS' });
            Notify({ severity: 'info', message: 'Loading data' });
        }
    },

    // retrieves user token from localStorage
    getToken: (id: string) => {
        let token = localStorage.getItem(id) || "";
        if (token !== "" && typeof token !== 'undefined' && token !== null)
            return JSON.parse(token);
        else
            return "";
    },

    // checks if user token is expired or not
    // expiration date is set in backend code when a user successfully logs in
    // and the the length of the time for the expiration date is identified based on
    // a setting in the appsettings.json file
    isTokenExpired: (item: any) => {
        let expiration = new Date(item.exp * 1000);
        if (expiration < new Date()) {
            Notify({severity: 'error', message: 'User token has expired. Please refresh your login and try again.'})
            return true;
        }
        else
            return false;
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LoginState = {
    loginFailed: false, user: {} as User, users: [] as User[], isAuthenticated: false, message: '', isLoaded: false, isLoading: false, refresh: false
};

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LOGIN':
            // user successfully logged in so update LoginState to indicate so for Login.tsx props
            if (action.message.includes('successful')) {
                return {
                    ...state,
                    isAuthenticated: true,
                    loginFailed: false
                }
            }
            else {
                return {
                    ...state,
                    loginFailed: true
                }
            }
        case 'REQUEST_USER':
            if (action.message.includes('successful')) {
                return {
                    ...state,
                    user: action.user
                };
            }
            else {
                return {
                    ...state
                }
            }
        case 'CHECK_LOGIN':
            return {
                ...state
            };
        case 'REQUEST_USERS':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_USERS':
            return {
                ...state,
                users: action.users,
                isLoaded: true,
                isLoading: false
            };
        case 'REFRESH_USERS':
            return {
                ...state,
                users: [],
                isLoaded: false,
                isLoading: false,
                refresh: false
            }
        case 'UPDATE_USER':
            if (action.message.includes('successful')) {
                return {
                    ...state,
                    user: action.user,
                    refresh: true
                };
            }
            else {
                return {
                    ...state
                };
            }
    }
    return state;
};