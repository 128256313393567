import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as ScadaStore from '../store/Scada';
import ScadaPage from './Scada/Scada';
import * as Login from '../store/Login';

/**
 * Receives props/state from Scada.ts
 * */

type ScadaProps =
    ScadaStore.ScadaState
    & typeof ScadaStore.actionCreators

class Scada extends React.PureComponent<ScadaProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        let user = Login.actionCreators.getUser();

        this.props.requestData(user.interconnect);
    }

    public render() {

        return (
            <React.Fragment>
                <ScadaPage {...this.props} />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.scada,
    ScadaStore.actionCreators
)(Scada as any);
