import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import useTable from '../common/Table.js'
import * as Login from '../../store/Login.ts'
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Controls from '../controls/Controls';

/**
 * This fiie is used by RemotePermissions.tsx
 * to pass to Table.js the appropriate columns to display on the page,
 * make available for exports, and applicable actions
 * @param {any} props
 */

export default function RemotePermissions(props) {

    const [helpPopup, setHelpPopup] = useState(false);
    const user = Login.actionCreators.getUser();

    const headCells = [
        { id: 'owningRemoteId', label: 'Owner Remote ID', filterComponent: (user.isAdmin || user.isModeler) ? true : false },
        { id: 'dataItem', label: 'DI', filterComponent: true },
        { id: 'readingRemoteId', label: 'Reading Remote ID', filterComponent: true },
        { id: 'readingRemoteName', label: 'Reading Remote Name' },
        { id: 'owningRemoteName', label: 'Owner Remote Name' }
    ];

    const actions = [
        (!user.isMpOnly) ? 
        {
            icon: 'add',
            tooltip: 'Create Submission to revoke read access for selected items',
            // passes the selected item data to RemotePermissions.ts createChange method
            onClick: (event, rowData) => {
                // appends the current user's usename to the list of data being
                // used to create a change, sets the submissionType to 'PERMISSION_REMOVE',
                // the remote name to the owning remote ID and converts the permission remove
                // list to an array
                const finalList = rowData.map((d, index) => (
                    {
                        ...d,
                        createdBy: user.userName,
                        submissionType: 'PERMISSION_REMOVE',
                        remoteName: d.owningRemoteId,
                        remoteAddOrRemove: d.readingRemoteId.split(','),
                        usses: false,
                        isModeled: false,
                        scadaPlaceholder: false
                    }))
                props.createChange(finalList, clearSelection);
            } 
            } : null,
        {
            icon: 'refresh',
            tooltip: 'Refresh data',
            isFreeAction: true,
            // calls the refreshData method in RemotePermissions.ts
            onClick: () => {
                props.refreshData()
            }
        },
        {
            icon: () => <HelpOutlineIcon />,
            tooltip: 'Click to see page info and actions',
            isFreeAction: true,
            // Sets helpPopup to true, which is used to determine
            // whether or not the Dialog defined near the bottom of the page
            // should be visible
            onClick: () => {
                setHelpPopup(true)
            }
        }
    ];

    // table option info passed to Table.js to define functionality that should be available to the user
    const tableOptions = {
        filtering: true,
        selection: true,
        columnResizable: true,
        draggable: false,
        hideFilterIcons: true,
        exportAllData: true,
        exportFileName: 'iccpst_remote_permissions'
    };

    // Holds the list of actions available on the page the user is looking at to provide guidance on how to perform them.
    // Description info varies based on if user is a read only user or not (IsMpOnly = RO)
    const TableDescription = () => {
        return (
            <div>
                <>
                    <p>This table displays all remotes that have read access to your remote's object IDs.</p>
                    <ul style={{listStyle: 'none'}}>
                        {!user.isMpOnly ?
                            <>
                                <li>Submissions may be created by selecting one or more entries in the table below and then clicking the <AddIcon /> icon in the top-right corner of this page.</li>
                            </>
                            : null}
                        <li>Data can be refreshed by clicking the <RefreshIcon /> icon in the top-right corner of this page.</li>
                        <li>Data can be exported by clicking the <SaveAltIcon /> icon in the top-right corner of this page.</li>
                    </ul>
                    <p>For a more in-depth overview and how-to examples, please refer to the following <a href={process.env.PUBLIC_URL + '/ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'} download={'ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'}>user guide</a>.</p>
                </>
            </div>
        );
    }

    // calls Table.js to use the useTable function to create a table with all of the data/settings
    // we've defined on this page.
    const {
        MtlTable,
        clearSelection
    } = useTable(props, headCells, actions, tableOptions, null, false);

    // Result displayed to user. Consists of a grid housing our custom table created from useTable in Table.js
    return (
        <Grid container>
            <Grid item xs={12}>
                <MtlTable />
            </Grid>

            {/*Dialog that pops up when a user clicks the help icon to get info about the page*/}
            <Dialog open={helpPopup}>
                <DialogTitle>
                    Page Details
                </DialogTitle>
                <DialogContent>
                    <TableDescription />
                    <br />
                    <br />
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='OK'
                        title='Click to close window.'
                        onClick={() => setHelpPopup(false)}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

