import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core'
import { isNullOrUndefined } from 'util';

export function useForm(initialValues, validateOnChange = false, validate) {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [errorsList, setErrorsList] = useState([]);

    const handleChange = e => {
        const { name, value } = e.target

        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({[name]: value})
    }

    const resetForm = () => {
        
        setValues(initialValues);
        setErrors({})
        setErrorsList([])
    }

    const formValidate = (fieldValues) => {
        let format = /[!@#%^&*()+\-=\[\]{};':" \\|,.<>\/?]+/;
        let temp = { ...errors }
        let validated = false;

        // data item field will always need to be populate regardless of submission type
        if (fieldValues.dataItem.length > 32)
            temp.dataItem = 'Object IDs must be 32 characters or less.'
        if (format.test(fieldValues.dataItem))
            temp.dataItem = 'Object IDs only allow $ and _ special characters.'
        if (isNullOrUndefined(fieldValues.dataItem) || fieldValues.dataItem === '')
            temp.dataItem = 'This field is required.'

        if (isNullOrUndefined(fieldValues.submissionType) || fieldValues.submissionType === '')
            temp.submissionType = 'This field is required.'

        if (fieldValues.submissionType === 'ADD') {

            // perform the following validation only if the submissionType is add.
            if (isNullOrUndefined(fieldValues.type) || fieldValues.type === '')
                temp.type = 'This field is required.'
            //if (isNullOrUndefined(fieldValues.emsStation) || fieldValues.emsStation === '')
            //    temp.emsStation = 'This field is required.'
            //if (isNullOrUndefined(fieldValues.deviceType) || fieldValues.deviceType === '')
            //    temp.deviceType = 'This field is required.'
            //if (isNullOrUndefined(fieldValues.deviceId) || fieldValues.deviceId === '')
            //    temp.deviceId = 'This field is required.'
            if (fieldValues.unitMeas.length > 10)
                temp.unitMeas = 'Measurement Type cannot exceed 10 characters.' 
            //if (isNullOrUndefined(fieldValues.unitMeas) || fieldValues.unitMeas === '') {
            //    temp.unitMeas += 'This field is required.'
            //}
        }

        if (fieldValues.submissionType === 'REPLACE') {
            // perform the following validation only if the submissionType is replace
            if (fieldValues.dataItemOld.length > 32) 
                temp.dataItemOld = 'Object IDs must be 32 characters or less.'
            if (format.test(fieldValues.dataItemOld))
                temp.dataItemOld = 'Object IDs only allow $ and _ special characters.'
            if (isNullOrUndefined(fieldValues.dataItemOld) || fieldValues.dataItemOld === '')
                temp.dataItemOld = 'This field is required.'
        }

        if (fieldValues.submissionType.includes('PERMISSION')) {
            // perform the following validation only if the submissionType is permission_grant or permission_remove
            if (fieldValues.remoteAddOrRemove.length === 0)
                temp.remoteAddOrRemove = 'You must select at least one Remote from the list.'
        }

        if (Object.keys(temp).length !== 0) {
            if (!isNullOrUndefined(fieldValues.dataItem) && fieldValues.dataItem !== '') {
                temp.name = fieldValues.dataItem
                if (!isNullOrUndefined(fieldValues.submissionType) && fieldValues.submissionType !== '')
                    temp.name += ' : ' + fieldValues.submissionType
            }
            else
                temp.name = 'Entry ' + (fieldValues.order + 1)

            setErrorsList(errorsList => [...errorsList, temp])
        }
        else
            validated = true;
        

        return validated;
    }

    return {
        values,
        setValues,
        errors,
        errorsList,
        setErrors,
        setErrorsList,
        handleChange,
        resetForm,
        formValidate
    }

}

const useStyles = makeStyles(theme => ({
    root: {
        //applies the below styling to all sub elements of the object with className root if they have the following control name 
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1),
            minWidth: '300px'
        }
    },
}));

export function Form(props) {
    const classes = useStyles();
    const { children, ...rest } = props;
    return (
        <form className={classes.root} autoComplete='off' {...rest}>
            {props.children}
        </form>
    )
}