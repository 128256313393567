import { Button as MuiButton, Tooltip, makeStyles } from '@material-ui/core';

export default function Button(props) {
    const useStyles = makeStyles(theme => ({
        root: {
            margin: theme.spacing(.5)
        }
    }));
    const { text, size, color, variant, onClick, title, ...rest } = props;
    const classes = useStyles();
    return (
        <Tooltip title={title}>
        <MuiButton className={classes.root}
            variant={variant}
            size={size}
            color={color}
            onClick={onClick}
            {...rest}>
            {text}
            </MuiButton>
        </Tooltip>
        
        )

}