import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import LayoutFormat from './Layout/Layout.js'

/**
 * Receives props/state from Login.ts
 * Is the primary file used to control all styling/visibility in ICCPST
 * */

type LoginProps =
    LoginStore.LoginState
    & typeof LoginStore.actionCreators

//export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
class Layout extends React.PureComponent<LoginProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        if (!this.props.isAuthenticated) {
            this.props.login()
        }
    }

    public render() {

        return (
            <React.Fragment>
                {this.renderLayout()}
            </React.Fragment>)
    }

    private renderLayout() {
        return (
            <LayoutFormat {...this.props} />
        );
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Layout as any);