import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import Controls from './Controls'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add';
import { isNullOrUndefined } from 'util';
import Companies from '../Companies.tsx'

const useStyles = makeStyles(theme =>({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}));

const submissionOptions =
    [
        { id: 'ADD', title: 'ADD' },
        { id: 'DELETE', title: 'DELETE' },
        { id: 'PERMISSION_GRANT', title: 'PERMISSION_GRANT' },
        { id: 'PERMISSION_REMOVE', title: 'PERMISSION_REMOVE' },
        { id: 'REPLACE', title: 'REPLACE' }
    ]

export default function Popup(props) {

    const { title, children, openPopup, setOpenPopup, addItem, finalize, addDetails, recordForEdit, setVerifyPopup, create, approve, reject, update, disabled, user, download } = props;
    const classes = useStyles();
    const [submissionType, setSubmissionType] = useState('')
    const [remoteAddOrRemove, setRemoteAddOrRemove] = useState([])

    return (

        <Dialog open={openPopup} maxWidth='md' classes={{paper: classes.dialog }} disableEnforceFocus>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h6' component='div' style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {(!isNullOrUndefined(addDetails)) ?
                        <>
                        <Controls.Select name='submissionType'
                            label='Submission Type'
                            options={submissionOptions}
                            onChange={(e) => { addDetails(e.target); setSubmissionType(e.target.value) }}
                            style={{ minWidth: 230 }}
                            value={submissionType}
                            title='Quickly set the submission type for all items below.'
                            disabled={!isNullOrUndefined(recordForEdit) ? (((recordForEdit.createdBy === user.userName || recordForEdit.createdByRemote === user.company) && (recordForEdit.status === 'DRAFT' || recordForEdit.status === '')) || ((user.isAdmin || user.isModeler) && recordForEdit.status !== 'SPP_APPROVED')) ? false : true : false}
                        />
                        <Companies name='remoteAddOrRemove'
                            label='Permissions'
                            onChange={(e) => { addDetails(e.target); setRemoteAddOrRemove(e.target.value) }}
                            value={remoteAddOrRemove}
                            multiple
                            style={{ minWidth: 130 }}
                            title='Quickly set the permissions for one or more remotes for all items below.'
                            disabled={!isNullOrUndefined(recordForEdit) ? (((recordForEdit.createdBy === user.userName || recordForEdit.createdByRemote === user.company) && (recordForEdit.status === 'DRAFT' || recordForEdit.status === '')) || ((user.isAdmin || user.isModeler) && recordForEdit.status !== 'SPP_APPROVED')) ? false : true : false}
                            />
                        </>
                        : null}
                    {(!isNullOrUndefined(addItem)) ?
                        <Controls.ActionButton
                            color='primary'
                            onClick={addItem}
                            title="Add another item to this submission"
                            disabled={!isNullOrUndefined(recordForEdit) ? (((recordForEdit.createdBy === user.userName || recordForEdit.createdByRemote === user.company) && (recordForEdit.status === 'DRAFT' || recordForEdit.status === '')) || ((user.isAdmin || user.isModeler) && recordForEdit.status !== 'SPP_APPROVED')) ? false : true : false}
                        >
                            <AddIcon />
                        </Controls.ActionButton>
                        : null}
                    <Controls.ActionButton
                        color='secondary'
                        onClick={() => {
                            setOpenPopup(false);
                            setSubmissionType('')
                            setRemoteAddOrRemove([])
                            finalize();
                        }}
                        title="Close windows"
                    >
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <div>{children}</div>
            </DialogContent>
            {(!isNullOrUndefined(addDetails)) ?
            <>
            {(!isNullOrUndefined(recordForEdit)) ?
                <div style={{ display: 'flex' }}>
                    <Controls.Button variant='contained'
                        color='primary'
                        onClick={update}
                        text='Update'
                        title='Submit to apply all changes.'
                        disabled={disabled}
                    />
                    {(recordForEdit.status !== 'SPP_APPROVED') ?
                        <Controls.Button variant='contained'
                            style={{ backgroundColor: '#009688', color: '#fff'}}
                            onClick={approve}
                            text={recordForEdit.status === 'DRAFT' ? 'Initiate' : 'Approve'}
                            title={recordForEdit.status === 'DRAFT' ? 'Submit submission for approval process.' : 'Approve submission'}
                                /> : null}
                    {(recordForEdit.status !== 'DRAFT' && recordForEdit.status !== 'SPP_APPROVED') ?
                        <Controls.Button variant='contained'
                            color='primary'
                            onClick={reject}
                            text='Reject'
                            title='Reject submission and return to DRAFT status.'
                        /> : null}
                    <Controls.Button
                        variant='contained'
                        color='default'
                        onClick={() => setVerifyPopup(true)}
                        text='Cancel Submission'
                        title='Cancel this submission.'
                        disabled={!(recordForEdit.createdBy === user.userName || recordForEdit.createdByRemote === user.company || user.isAdmin || user.isModeler)}
                            />
                    <Controls.Button
                        variant='contained'
                        color='default'
                        onClick={download}
                        text='Download Data'
                        title='Download contents of submission as a csv file.'
                    />

                </div> :
                <div style={{ display: 'flex' }}>
                    <Controls.Button variant='contained'
                        color='primary'
                        onClick={create}
                        text='Create Draft'
                        title='Submit to create a Draft Change.'
                        />
                </div>}

                </> :
                null}
    </Dialog>
    )
}