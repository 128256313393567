import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.Mui-selected': {
            backgroundColor: 'lightblue'
        },
    },
    select: {
        margin: theme.spacing(.5)
    }
}))

export default function Select(props) {
    const { name, label, value, onChange, options, error = null, required, ...rest } = props;

    const classes = useStyles();

    return (
        <FormControl variant='outlined' {...(error && { error: true })} required={required} className={classes.select}>
            <InputLabel>
                {label}
            </InputLabel>
            <MuiSelect name={name}
                label={label}
                value={value}
                onChange={onChange}
                options={options}
                {...rest}>
                {
                    options.map(item => (
                        <MenuItem key={item.id} value={item.id} classes={{ selected: classes.root }}>
                            {item.title}
                        </MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        
        )
}
