import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as CompaniesStore from '../store/Companies';
import CompaniesPage from './Companies/Companies';

/**
 * Receives props/state from Companies.ts
 * */

type CompaniesProps =
    CompaniesStore.CompanyState
    & typeof CompaniesStore.actionCreators

class Companies extends React.PureComponent<CompaniesProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestData();
    }

    public render() {

        return (
            <React.Fragment>
                <CompaniesPage {...this.props} />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.companies,
    CompaniesStore.actionCreators
)(Companies as any);
