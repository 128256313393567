import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import UserPage from './Users/Users';

/**
 * Receives props/state from Scada.ts
 * */

type UserProps =
    LoginStore.LoginState
    & typeof LoginStore.actionCreators

class Scada extends React.PureComponent<UserProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        // get list of all users in ICCPST
        this.props.getUsers();
        this.props.refreshData();
    }

    public render() {

        return (
            <React.Fragment>
                <UserPage {...this.props} source={Object.assign({ data: this.props.users, from: 'Users' })} view='list' />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Scada as any);
