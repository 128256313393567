import React, { useState } from 'react';
import logo from '../../SPPLogo_Light.png'
import { makeStyles } from '@material-ui/core';
import { Grid, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import Popup from '../controls/Popup';
import Users from '../Users/Users';
import { isNullOrUndefined } from 'util';

const styles = makeStyles(theme => ({
    root: {
        backgroundColor: '#393f47',
        paddingLeft: '220px',
        color: '#fff'
    },
    header: {
        display: 'flex',
        '& img': {
            height: '60px'
        },
        '& h6': {
            margin: 'auto'
        }
    },
    user: {
        cursor: 'pointer',
        '& svg': {
            color: '#fff'
            }
    },
    
}));

/**
 * Header visible on all pages. Displays the application name and has a link to the user's profile
 * Once they've logged in successfully. Uses the style information defined above to display contents below 
 * in a stylized format.
 * Called from layout.js.
 * @param {any} props
 */
const Header = (props) => {
    const classes = styles();
    const [openPopup, setOpenPopup] = useState(false);
    let user = isNullOrUndefined(props.user) ? 'Unauthenticated' : props.user.fullName
    

    const finalize = () => {
        setOpenPopup(false)
    }

    return (
        <AppBar position='static' className={classes.root} style={{ backgroundColor: '#393f47'}}>
            <Toolbar>
                <Grid container>
                    <Grid item className={classes.header}>
                        <img src={logo} alt='logo' />
                        <Typography variant='h6'>
                            ICCP Submission Tool
                        </Typography>
                    </Grid>
                    <Grid item sm></Grid>
                    {/*Person icon that displays user information if the user successfully logs in.*/}
                    {/*Can also be clicked to open the Popup object defined further below.*/}
                    <Grid item className={classes.user} onClick={() => props.isAuthenticated ? setOpenPopup(true) : setOpenPopup(false)}>
                        <IconButton>
                            <PersonOutlineOutlinedIcon fontSize='large' />
                        </IconButton>
                        {user}
                    </Grid>
                </Grid>
            </Toolbar>
            {/*Popup window that routes to a user's profile*/}
            {/*When the person icon defined above is clicked on.*/}
            <Popup title='User Profile'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                finalize={finalize}
            >
                <Users {...props} source={Object.assign({from:'Users'})} view='single' />
            </Popup>
        </AppBar>
    )
};

export default Header