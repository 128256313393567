import React from 'react';
import Controls from '../controls/Controls';

/**
 * This file is used by Companies.tsx to display a list of all available companies in ICCPST
 * @param {any} props
 */

export default function Companies(props) {

    const remoteOptions = props.data.map((company, index) => (
        { id: company.id, title: company.id }
    ))

    const { onChange, value, name, label, ...rest } = props

    return (
        <Controls.Select name={name}
            label={label}
            options={remoteOptions}
            value={value}
            onChange={onChange}
            {...rest}
        />
    )
}