import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { useState, useEffect } from 'react';
import Select from 'react-select'
import Controls from '../controls/Controls';
import { isNullOrUndefined } from 'util';

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogPaper: {
        minHeight: '80vh'
    },
    dialogTitle: {
        //paddingRight: '0px',
        textAlign: 'center'
    },
    header: {
        display: 'flex'
    },
    title: {
        padding: theme.spacing(2)
    }
}));

/**
 * This file is used by Scada.tsx and is triggered from SubmissionForm.js when a user
 * is viewing/creating an ADD type submission item.
 * This file provides a control button and Dialog window that becomes visible when the control button
 * is clicked. It displays all available SPP EMS SCADA data to the user depending on their interconnect
 * and allows them to select values to apply to their submission item.
 * @param {any} props
 */

export default function Scada(props) {

    const { recordForEdit, disabled } = props;
    const [openPopup, setOpenPopup] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataLists, setFilteredDataLists] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [values, setValues] = useState({});

    const classes = useStyles();

    const headCells = [
        { id: 'areaEms', label: 'EMS Area' },
        { id: 'emsStation', label: 'Station' },
        { id: 'deviceType', label: 'Device Type' },
        { id: 'deviceId', label: 'Device Name' },
        { id: 'unitMeas', label: 'Measurement Type' }
    ];

    const scadaCells = [
        { id: 'emsStation', label: 'Station' },
        { id: 'deviceType', label: 'Device Type' },
        { id: 'deviceId', label: 'Device Name' },
        { id: 'unitMeas', label: 'Measurement Type' }
    ]

    // custom filter functionality to update the available filter options in response to existing filters the
    // user has already applied or is applying. This helps prevent invalid options from being available based on
    // existing selections
    const handleChange = (e, id) => {
        // Declare local currentFilters. This will retain a current version of activeFilters, which is updated on state changes
        // So when a filter is added or removed it'll appear a step behind, but is needed to retain list of existing filters
        let currentFilters;
        if (!isNullOrUndefined(e)) {
            // maintain a list of filters as state level so we can determine when there are multiple active
            setActiveFilters([...activeFilters, Object.assign({}, { id: id, value: e.value })])
            currentFilters = [...activeFilters, Object.assign({}, { id: id, value: e.value })]
            // Set local copy of values that'll be applied back to the submission if the user clicks the Apply Mappings button
            setValues({
                ...values,
                [id]: e.value
            });
        }
        else {
            setActiveFilters([...activeFilters.filter(a => a.id !== id)])
            currentFilters = [...activeFilters.filter(a => a.id !== id)]
            // Set local copy of values that'll be applied back to the submission if the user clicks the Apply Mappings button
            setValues({
                ...values,
                [id]: recordForEdit[id]
            });
        }

        let data = [...props.data];

        let filterData;

        // TODO determine best way to remove filters from filteredData while retaining any still existing filters
        if (isNullOrUndefined(e)) {
            // check if there are any existing filters remaining. If all have been removed, don't try cycling through the filter list to filter data
            if (currentFilters.length > 0) {
                for (var i in currentFilters) {
                    if (isNullOrUndefined(filterData))
                        filterData = data.filter(d => d[currentFilters[i].id] === currentFilters[i].value)
                    else
                        filterData = filterData.filter(d => d[currentFilters[i].id] === currentFilters[i].value)
                }
            }
            // set filterData to our full data list since there are no active filters
            else
                filterData = data
        }
        else {
            // if we already have existing filters and filteredData isn't empty (i.e. we're not using our first filter), 
            // filter our already filtered data so that we retain all active filters.
            if (currentFilters.length > 0 && filteredData.length > 0) {
                filterData = filteredData.filter(a => a[id] === e.value);
            }
            else
                filterData = data.filter(a => a[id] === e.value);
        }

        setFilteredData(filterData);

        // set a state level array of filters lists that'll be use to display our updated options in each of the Select windows.
        // only proceed with mapping filterData to filteredLists if there's at least one active filter in use. If none, skip and reset
        // filteredDataLists to props.filterLists
        if (currentFilters.length > 0) {
            const filteredLists = headCells.map(c => {
                return {
                    [c.id]: [...new Set(filterData.map(f => f[c.id]))].sort((a, b) => { return !(isNullOrUndefined(a)) ? a.toString().localeCompare(b) : "" })
                }
            }).reduce(((r, c) => Object.assign(r, c)), {});

            setFilteredDataLists(filteredLists);
        }
        else
            setFilteredDataLists(props.filterLists);
    }

    // clear all data set on Scada.js and closes the Dialog
    function clearData() {
        setOpenPopup(false);
        setFilteredData([]);
        setFilteredDataLists([]);
        setActiveFilters([]);
        setValues([]);
    }

    // The display returned to the user. Only the button is visible by default.
    // The Dialog window becomes visible once the user clicks on the button
    return (
        <>
            <Controls.Button
                variant='contained'
                color='primary'
                onClick={() => setOpenPopup(true)}
                disabled={disabled}
                text='Map SCADA to SPP EMS SCADA'
                title='Click to view and assign available SPP EMS SCADA mappings.'
            />
            <Dialog open={openPopup} className={classes.dialog} >
                <DialogTitle className={classes.dialogTitle} >
                    SPP SCADA EMS References
                </DialogTitle>
                <DialogContent>
                    {!isNullOrUndefined(recordForEdit) ?
                        <div className={classes.header}>
                            {scadaCells.map((cell, index) => (
                            <>
                                    {
                                        recordForEdit[cell.id] !== '' ?
                                        <div className={classes.title} key={index}>
                                            {cell.label}
                                            <br />
                                            {recordForEdit[cell.id]}
                                        </div> :
                                        null
                                    }
                                </>
                            ))}
                        </div> :
                        null}

                    {headCells.map((cell, index) => (
                        <div>
                            {cell.label}
                            <Select
                                isLoading={props.isLoading}
                                isDisabled={!props.isLoaded}
                                isSearchable
                                isClearable
                                onChange={(e) => handleChange(e, cell.id)}
                                options={filteredDataLists.length !== 0 ? filteredDataLists[cell.id].map(i => { return { value: i, label: i } }) : props.filterLists.length !== 0 ? props.filterLists[cell.id].map(i => { return { value: i, label: i } }) : []}
                                key={index}
                            />
                        </div>
                    ))}
                     {/*Applies the user's selected mappings to the submission item this file was opened from*/}
                     {/*and clears all selections made on Scada.js*/}
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        text='Apply Mappings'
                        title='Apply selected SCADA mappings to submission item.'
                        onClick={() => props.setScadaMappings(values, clearData)}
                    />
                    {/*Clears all selections made on Scada.js*/}
                    <Controls.Button
                        variant='contained'
                        color='secondary'
                        text='Close'
                        title='Close popup.'
                        onClick={() => clearData()}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
};