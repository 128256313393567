import React, { useRef, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns';
import { isNullOrUndefined } from 'util';
import Select from 'react-select';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { CsvBuilder } from 'filefy';
import * as Login from '../../store/Login';
import { createTheme, ThemeProvider } from '@mui/material';

let user = Login.actionCreators.getUser();

const styles = makeStyles(theme => ({
    table: {

    },
    display: {
        width: '100%',
        display: 'flex',
        marginTop: 15
    },
    toolbarLeft: {
        display: 'flex',
        width: '50%'
    },
    toolbarRight: {
        width: '50%'
    },
    formControlToolbar: {
        minWidth: 235,
    },
    select: {
        textAlign: 'left'
    },
    selectedRow: {
        backgroundColor: '#6d798a'
    }
}))

// defines the columns to be generated for a detail table when the displayDetailTable variable is passed to useTable as true
const detailHeadCells = [
    { id: 'submissionType', label: 'Submission Type' },
    { id: 'scadaPlaceholder', label: 'SCADA Placeholder', hidden: (!isNullOrUndefined(user) && (user.isAdmin || user.isModeler)) ? false : true },
    { id: 'dataItemOld', label: 'Old Object ID' },
    { id: 'dataItem', label: 'Object ID' },
    { id: 'remoteName', label: 'Remote' },
    { id: 'qualityStatus', label: 'Quality' },
    { id: 'tsStatus', label: 'Timestamp' },
    { id: 'emsStation', label: 'Station' },
    { id: 'deviceType', label: 'Device Type' },
    { id: 'deviceId', label: 'Device Name' },
    { id: 'unitMeas', label: 'Measurement Type' },
    { id: 'remoteAddOrRemove', label: 'Permit' },
    { id: 'areaEms', label: 'EMS Area' },
    { id: 'interconnect', label: 'Interconnect' },
    { id: 'comments', label: 'Comments' },
    { id: 'createdBy', label: 'Requested By', hidden: true },
    { id: 'createdByDisplayName', label: 'Requested By' },
    { id: 'createdByRemote', label: 'Requested By Remote' },
    { id: 'createdOn', label: 'Requested On', render: rowData => rowData.createdOn === null ? null : format(new Date(rowData.createdOn), 'MM/dd/yy hh:mm:ss a') },
    { id: 'updatedBy', label: 'Updated By', hidden: true },
    { id: 'updatedByDisplayName', label: 'Updated By' },
    { id: 'updatedOn', label: 'Updated On', render: rowData => rowData.createdOn === null ? null : format(new Date(rowData.createdOn), 'MM/dd/yy hh:mm:ss a') }
]

/**
 * Called by any page that displays table data (Submissions.Js, DataPoints.js, RemotePermissions.js)
 * @param {any} source
 * @param {any} headCells
 * @param {any} actions
 * @param {any} tableOptions
 * @param {any} ToolbarOptions
 * @param {any} displayDetailTable
 */

export default function useTable(source, headCells, actions, tableOptions, ToolbarOptions, displayDetailTable) {
    const height = (window.innerHeight - 64 - 64 - 71 - 53 - 1) / window.innerHeight * 100;
    const classes = styles();
    const tableRef = useRef();
    const [pageSize, setPageSize] = useState(localStorage.getItem(source.from +'pageSize') === null ? 25 : parseInt(localStorage.getItem(source.from+'pageSize')))
    const theme = createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'standard'
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    displayedRows: {
                        margin: 0
                    },
                    selectLabel: {
                        margin: 0
                    }
                }
            }
        }
    });

    // Custom Filter used by any headCells passed to useTable with filterComponent set to true
    const CustomFilter = (props) => {
        const { isSearchable, columnDef, onFilterChanged } = props
        let options;
        if (source.filterLists.length !== 0) {
            // check if a filter has been applied that would affect the currently displayed data
            if (tableRef.current && tableRef.current.dataManager.filteredData.length !== tableRef.current.dataManager.data.length) {

                // Extract list of columns currently visible to the user. These will be used to map filter data in a bit
                const columns = tableRef.current.props.columns.map(i => {
                    return i.field
                });
                // create trimmed down filterList of valid values based on existing filtered from tableRef.current.dataManager.filteredData
                // that holds unique values and will be used to update the options available
                // on each column drop-down
                const filterLists = columns.map(c => {
                    return {
                        // Imitate the structure of source.filterLists by setting the column name (c) as the object name and its value to an array of all possible values for that column.
                        // use Set(...) to trim the mapped columns/filteredData down to unique values and then finish with a sort using string.localeCompare to sort the new filter options alphabetically.
                        [c]: [...new Set(tableRef.current.dataManager.filteredData.map(f => f[c]))].sort((a, b) => { return !(isNullOrUndefined(a)) ? a.toString().localeCompare(b) : "" })
                    }
                    // We then use reduce to squish the array of objects created from the above return {...} and shape it into an object of arrays like the existing source.filterLists is.
                }).reduce(((r, c) => Object.assign(r, c)), {})
                
                // then set options to the filterLists values based on the incoming columnDef.field to update the values available to the user in the UI
                options = filterLists[columnDef.field].map(i => {
                    return {
                        value: i,
                        label: i === '   ' ? 'No Mapping' : i
                    }
                });
            }
            else {
                options = source.filterLists[columnDef.field].map(i => {
                    return {
                        value: i,
                        label: i
                    }
                });
            }
        }
        else
            options = [];

        function handleChange(e) {
            let val;
            if (!isNullOrUndefined(e)) {
                val = e.value
            }
            else {
                val = null
            }
            if (val === 'No Mapping')
                onFilterChanged(columnDef.tableData.id, '   ');
            else 
                onFilterChanged(columnDef.tableData.id, val);
            
        }

        return (
            <Select
                isClearable
                isSearchable={!isNullOrUndefined(isSearchable) ? isSearchable : true}
                options={options}
                onChange={handleChange} />
            )
    }

    // Clears all selected entries when called
    const clearSelection = () => {
        if (tableRef.current)
            tableRef.current.onAllSelected(false);

    }

    const MtlTable = props => {

        // call clearSelection to clear any selected items that may be leftover from previous time user loaded page.
        clearSelection();
        return (
            <ThemeProvider theme={theme}>
            <MaterialTable
                tableRef={tableRef}
                columns={headCells.map(headCell => (
                    {
                        title: headCell.label,
                        field: headCell.id,
                        type: headCell.type,
                        // disable filtering by passing filtering: false from source data
                        filtering: headCell.filtering,
                        // disable sorting by passing sorting: false from source data
                        sorting: headCell.sorting,
                        // use lookup to create drop-down menu filters
                        // for specific columns, such as Owner, Operator, Interconnet and Type
                        lookup: headCell.lookup,
                        render: headCell.render,
                        hidden: headCell.hidden,
                        editable: 'never',
                        cellStyle: {
                            whiteSpace: 'nowrap'
                        },
                        // use in place of lookup to use a custom designed filter drop-down menu with 'smart' filtering options that respond to user inputs
                        filterComponent: headCell.filterComponent === true ? (props) =>
                            <CustomFilter isSearchable={headCell.isSearchable} columnDef={props.columnDef} onFilterChanged={props.onFilterChanged} />
                            : null,
                        maxWidth: headCell.width,
                        filterCellStyle: {
                            minWidth: 200
                        },
                        export: headCell.export
                    }
                ))}
                data={source.data}
                detailPanel={displayDetailTable ? rowData => {

                    return (
                        <MaterialTable
                            columns={detailHeadCells.map(detailCell => (
                                {
                                    title: detailCell.label,
                                    field: detailCell.id,
                                    hidden: detailCell.hidden,
                                    render: detailCell.render
                                }
                            ))}
                            data={rowData.submissionDetails}
                            components={{
                                Container: props => <div>{props.children}</div>,
                            }}
                            options={{
                                showTitle: false,
                                filtering: false,
                                toolbar: false,
                                search: false,
                                paging: false,
                                detailPanel: {
                                    display: false
                                },
                                headerStyle: {
                                    whiteSpace: 'nowrap',
                                    zIndex: 0,
                                    fontWeight: 'bold'
                                },
                                cellStyle: {
                                    whiteSpace: 'nowrap'
                                }
                            }}
                        />)
                } : null}
                onRowClick={(event, rowData, togglePanel) => { if (displayDetailTable) togglePanel() }}
                    onChangeRowsPerPage={(pageSize) => {
                    localStorage.setItem(source.from+'pageSize', pageSize)
                    setPageSize(pageSize)
                    }}
                //onRowClick={(event, rowData) => { setSelection(rowData);  }}
                // this section is used to revamp theming/layouts for the various parts of Material Table
                components={{
                    // This allows us to remove the default Paper theme of the Container for the entire table
                    Container: props => <div>{props.children}</div>,
                    // Resetting the display for the Toolbar so that I can split up where the
                    // PSSE type drop-down menu sits and the toolbar search and actions.
                    Toolbar: props => (
                        <div className={classes.display}>
                            <div className={classes.toolbarLeft}>
                                {/*<ToolbarOptions />*/}
                            </div>
                            <div className={classes.toolbarRight}>
                                <MTableToolbar {...props} />
                            </div>
                        </div>)
                }}
                // Using Object.assign to merge custom tableOption settings from source
                // and shared table options or unique table options that use 
                // variables defined within Table.js here
                options={Object.assign(tableOptions,
                    {
                        showTitle: false,
                        paginationPosition: 'bottom',
                        pageSize: pageSize,
                        pageSizeOptions: [25, 50, 100, 150],

                        // have to use maxBodyHeight with position: 'sticky'
                        // to make both header columns and filter section stay on screen
                        // added zIndex to header and filter rows so that custom-rendered
                        // rows below them don't show through when scrolling
                        maxBodyHeight: `${height}vh`,
                        minBodyHeight: `0vh`,
                        headerStyle: {
                            whiteSpace: 'nowrap',
                            backgroundColor: '#fafafa',
                            paddingTop: 20,
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            fontSize: 18,
                            fontWeight: 'bold'
                        },
                        filterCellStyle: {
                            backgroundColor: '#fafafa',
                            position: 'sticky',
                            top: 61,
                            zIndex: 1,
                            width: 300
                        },
                        // set loadingType so when isLoading is true is doesn't default to overlay and lock up the entire display
                        loadingType: 'linear'
                        //rowStyle: rowData => ({
                        //    backgroundColor: selectedRows.filter(item => item.tableData.id === rowData.tableData.id).length > 0 ? "#6d798a" : "fff"
                        //})
                    }
                )}
                // merge actons array from source file with shared action here via array.concat
                actions={[
                    {
                        icon: 'clear',
                        tooltip: 'Clear selections',
                        position: 'toolbarOnSelect',
                        // clears all selected items
                        onClick: (event, data) => {
                            tableRef.current.onAllSelected(false);
                            //setSelectedRows([]);
                        }
                    },
                    // Export option to export all data w/wo filters applied.
                    // Only available if source js file passes in tableOptions.exportAllData
                    tableOptions.exportAllData ? 
                    {
                        icon: () => <SaveAltIcon />,
                        position: 'toolbar',
                        tooltip: 'Export data to csv',
                            onClick: (e) => {
                            const fileName = tableOptions.exportFileName;
                            const builder = new CsvBuilder(fileName + '.csv');

                            builder.setColumns(headCells.filter(headCell => { if (isNullOrUndefined(headCell.export) || headCell.export === true) return headCell }).map((columnDef) => columnDef.label))
                                .addRows(tableRef.current.dataManager.filteredData.map((rowData) =>
                                    headCells.filter(headCell => { if (isNullOrUndefined(headCell.export) || headCell.export === true) return headCell }).map((columnDef) => rowData[columnDef.id])
                                ))
                                .exportFile();
                        }
                        } : null,
                    // Export option to export selected data.
                    // Only available if source js file has tableOptions.selection enabled
                    {
                        icon: () => <SaveAltIcon />,
                        position: 'toolbarOnSelect',
                        tooltip: 'Export selected data to csv',
                        onClick: (e, rowData) => {
                            const fileName = tableOptions.exportFileName;
                            const builder = new CsvBuilder(fileName + '.csv');

                            builder.setColumns(headCells.filter(headCell => { if (isNullOrUndefined(headCell.export) || headCell.export === true) return headCell }).map((columnDef) => columnDef.label))
                                .addRows(rowData.map((rowData) =>
                                    headCells.filter(headCell => { if (isNullOrUndefined(headCell.export) || headCell.export === true) return headCell }).map((columnDef) => rowData[columnDef.id])
                                ))
                                .exportFile();
                        }
                    }                ].concat(actions)
                }
                // show isLoading icon only when we haven't received data yet from API calls
                isLoading={source.isLoading ? true : false}
                localization={{
                    toolbar: {
                        //exportPDFName: "Export for PSSE",
                        // might add some tooltip settings here
                    },
                    // hiding action header title
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: ''
                    }
                }}
                />
            </ThemeProvider>
            )
    }

    return {
        MtlTable,
        clearSelection
    }
}
