import { CssBaseline } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Header from "../Header/Header";
import SideMenu from '../Menu/SideMenu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

/**
 * The primary formatting/styling used for all of ICCPST
 * This page isused to determine if a user is able to view
 * any data or not depending on if they logged in successfully
 * and also fits in the side menu and header so that all pages fit together the same
 * @param {any} props
 */

const Layout = (props) => {

    const [height, setHeight] = useState(window.innerHeight);

    const updateHeight = () => {
        setHeight(window.innerHeight)
    }

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: height
        },
        center: {
            paddingLeft: '245px',
            paddingTop: theme.spacing(2),
            flexGrow: 1,
            minHeight: 0,
            overflow: 'scroll'
        },
        display: {
            textAlign: 'center',
            minHeight: 400,
            height: '100%'
        }
    }));

    const classes = useStyles();

    useEffect(() => (window.onresize = updateHeight), [])

    
    // results displayed to user. Consists of the side menu and header regardless of login status
    // and either makes data visible based on user's side menu selection or displays the 
    // appropriate login failed message if the user failed to authenticate/login
    return (
        <div className={classes.root}>
            <SideMenu {...props}/>
            <Header {...props}/>
            <div className={classes.center}>
                    
                    {(props.isAuthenticated && props.user.fullName !== undefined) ?
                        props.children
                        :
                        props.loginFailed ?
                            <div className={classes.display}>
                                Login Failed. <br />
                                {props.message}
                            </div>
                            :
                            <div className={classes.display}>
                                Loading...
                            </div>
                    }
            </div>
            {/*Declare ToastContainer here to allow for use of popout notifications that disappear either when clicked on*/}
            {/*or after a set amount of time. Since all pages are passed through here via props.children via App.tsx and Layout.tsx*/}
            {/*this container only needs to be declared once*/}
            <ToastContainer />
            <CssBaseline />
        </div>
        )
};

export default Layout