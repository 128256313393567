import React from 'react';
import { Button, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        margin: theme.spacing(.5)
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color: theme.palette.secondary.main
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color: theme.palette.primary.main
        }
    }
}));

export default function ActionButton(props) {
    const { color, children, onClick, title, ...rest } = props;
    const classes = useStyles();
    return (
        <Tooltip title={title}>
            <Button className={`${classes.root} ${classes[color]}`}
                onClick={onClick}
                {...rest}
            >
            {children}
            </Button>
        </Tooltip>

    )

}