import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as SubmissionsStore from '../store/Submissions';
import SubmissionsPage from './Submissions/Submissions.js';
import * as Login from '../store/Login';

/**
 * Receives props/state from Submissions.ts
 * */

type SubmissionsProps =
    SubmissionsStore.SubmissionState
    & typeof SubmissionsStore.actionCreators

class Submissions extends React.PureComponent<SubmissionsProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        let user = Login.actionCreators.getUser();
        this.props.requestData(user.interconnect, 'current');
        this.props.refreshData('current');
    }

    public render() {

        return (
            <React.Fragment>
                <SubmissionsPage source={Object.assign({ data: this.props.data, isLoading: this.props.isLoading, filterLists: this.props.filterLists, type: 'current', refreshDate: this.props.refreshDate, from: 'currentSubmissions' })}
                    {...this.props}
                />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.submissions,
    SubmissionsStore.actionCreators
)(Submissions as any);
