import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notify = (props) => {
    const { severity, message } = props

    // display a drop-down notifcation message that fades after a bit using the severity level set in props and also the props message
    // message is only displayed if the user is focused on the page. This prevents a build up on notification windows if the user looks at
    // a different browser tab and ICCPST continues to refresh data
    if (!document.hidden)
        toast[severity](message, { position: 'top-center', pauseOnFocusLoss: false })
}

export default Notify
