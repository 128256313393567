import { makeStyles } from '@material-ui/core';
import { MenuItem, MenuList } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '0px',
        width: '240px',
        height: '100%',
        backgroundColor: '#393f47',
        paddingBottom: theme.spacing(2),
        color: '#fff',
        '& a:hover': {
            backgroundColor: '#5f6670',
            color: '#fff'
        },
        '& .Mui-selected': {
            backgroundColor: '#5f6670 !important',
            color: '#fff !important'
        }
    }
}));

/**
 * Side menu that lists all navigation options available to users.
 * Called from Layout.js
 * @param {any} props
 */
const SideMenu = (props) => {
    const classes = useStyles();
    const location = useLocation();

    const menuOptions = [
        {
            id: 'Home',
            toPath: '/',
        },
        {
            id: 'Submissions',
            toPath: '/submissions',
        },
        {
            id: 'Upcoming Model',
            toPath: '/future-model'
        },
        {
            id: 'Data Points',
            toPath: '/data-points',
        },
        {
            id: 'Existing Permissions',
            toPath: '/existing-permissions'
        },
        {
            id: 'Archived Submissions',
            toPath: '/archive',
        },
        // Only make the Users page visible/accessible if the user is an SPP user
        (props.user.isAdmin || props.user.isModeler) ?
            {
            id: 'Users',
            toPath: '/users'
            }
            :
            null
    ];
    // returned MenuList displayed to users. Uses the array of menuOptions defined above to quickly map MenuItems to each available option rather
    // than us manually writing out a full list of MenuItems to display each one.
    // Also has a div at the bottom of the returned menu that represents the footer.
    return (

        <div className={classes.root}>
            <MenuList>
                {menuOptions.map((menuOption, index) => (
                    (menuOption !== null) ?
                        <MenuItem tag={Link} component={Link} to={menuOption.toPath} key={menuOption.id} selected={menuOption.toPath === location.pathname}>
                            {menuOption.id}
                        </MenuItem> : null
                ))}
            </MenuList>
            <div style={{marginTop: 'auto', textAlign: 'center' }}>&#169;2021 Southwest Power Pool</div>
        </div>
        );
}

export default SideMenu