import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import DataPoints from './components/CurrentDataPoints';
import Submissions from './components/Submissions';
import './custom.css'
import RemotePermissions from './components/RemotePermissions';
import FutureDataPoints from './components/FutureDataPoints';
import ArchiveSubmissions from './components/ArchiveSubmissions';
import Users from './components/Users';

export default () => (
    <Layout>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/data-points' element={<DataPoints />} />
            <Route path='/future-model' element={<FutureDataPoints />} />
            <Route path='/submissions' element={<Submissions />} />
            <Route path='/existing-permissions' element={<RemotePermissions />} />
            <Route path='/archive' element={<ArchiveSubmissions />} />
            <Route path='/users' element={<Users />} />
        </Routes>
    </Layout>
);
