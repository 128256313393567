import { Divider } from '@mui/material';
import { connect } from 'react-redux';
import PendingSubmissions from './PendingSubmissions';

/**
 * Home page that lists all available actions in the tool to users. This is the first page a user is sent to when 
 * they successfully log into ICCPST
 * */

const Home = () => (
  <div>
        <p>Welcome to the ICCP Submission Tool</p>
        <p>This application will allow you to:</p>
        <ul>
            <li>Set user display name, contact information, and email preferences by clicking the user icon or your display name in the top-right corner. You can also see what remote you're associated with in this tool.</li>
            <li>View changes currently in progress (not yet approved by SPP) that affect your remote's interconnect.</li>
            <li>Submit changes to be implemented to the ICCP model by SPP personnel.</li>
            <li>View upcoming changes (additions, replacements, deletions, etc.) to the ICCP model that affect your remote.</li>
            <li>View existing object IDs in the ICCP model in your remote's interconnect.</li>
            <li>View remotes that have read access to your remote's object IDs as well as object IDs your remote has read access to in the Existing Permissions tab.</li>
            <li>View completed changes that have been added to the ICCP model that affect your remote.</li>
        </ul>
        <p>For a more in-depth overview and how-to examples, please refer to the following <a href={process.env.PUBLIC_URL + '/ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'} download={'ICCP ONLINE SUBMISSION TOOL User Manual External.pdf'}>user guide</a>.</p>
        <p>If you feel that you are missing access in this application or not seeing all the data you should be. Please contact SPP's ICCP modeling personnel.</p>
        <Divider variant='middle'/>
        <PendingSubmissions />
  </div>
);

export default connect()(Home);
