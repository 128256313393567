import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import Notify from '../components/common/Notify';
import * as Login from './Login';

/**
 * This ts file handles all API calls to/from ScadaController.cs
 * */

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScadaState {
    data: Scada[];
    filterLists: [];
    isLoading: boolean;
    isLoaded: boolean;
}

export interface Scada {
    areaEms: string;
    deviceId: string;
    devicetype: string;
    emsStation: string;
    topologyKeyEms: string;
    unitMeas: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDataAction {
    type: 'SCADA_REQUEST_DATA';
}

interface ReceiveDataAction {
    type: 'SCADA_RECEIVE_DATA';
    scada: Scada[];
    filterLists: [];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDataAction | ReceiveDataAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// All of the below fetch API calls go to the ModelPointController.cs file
export const actionCreators = {
    requestData: (interconnect: string): AppThunkAction<KnownAction> => (dispatch, getState) =>
    {
        let user = Login.actionCreators.getUser();
        const appState = getState();

        if (appState && appState.scada && !appState.scada.isLoading && !appState.scada.isLoaded) {
            fetch('api/scada/get', {
                method: 'POST',
                body: JSON.stringify({
                    UserName: user.userName,
                    Interconnect: interconnect
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'SCADA_RECEIVE_DATA', scada: data.dtos, filterLists: data.filterLists });
                                if (data.length === 0)
                                    Notify({ severity: 'info', message: 'No data available.' });
                            })
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'SCADA_REQUEST_DATA' });
                                Notify({ severity: 'error', message: data.message });
                            })
                    }
                })
            dispatch({ type: 'SCADA_REQUEST_DATA' });
            Notify({ severity: 'info', message: 'Loading data' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ScadaState = { isLoading: false, isLoaded: false, data: [], filterLists: [] };

export const reducer: Reducer<ScadaState> = (state: ScadaState | undefined, incomingAction: Action): ScadaState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SCADA_REQUEST_DATA':
            return {
                ...state,
                isLoading: true
            };
        case 'SCADA_RECEIVE_DATA':
            return {
                data: action.scada,
                filterLists: action.filterLists,
                isLoading: false,
                isLoaded: true
            };
    }
    return state;
};