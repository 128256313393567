import { Collapse, Typography } from '@material-ui/core';
import {useState} from 'react';
import Controls from './Controls';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


export default function DropDown(props) {
    const { children, title } = props;
    const [dropDownOpen, setDropDownOpen] = useState(false);
    return (
        <div style={{ borderBottom: dropDownOpen ? null : '2px solid rgba(0, 0, 0, 0.12)', paddingTop: '20px' }}>
            <div style={{ display: 'flex'}}>
                <Typography variant='h6' component='div' style={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                <Controls.ActionButton
                    color='primary'
                    onClick={() => setDropDownOpen(!dropDownOpen)}
                    title={dropDownOpen ? 'Minimize item.' : 'Expand item.'}
                >
                    {dropDownOpen ? <RemoveIcon /> : <AddIcon />}
                </Controls.ActionButton>
            </div>
            <Collapse in={dropDownOpen} orientation='horizontal'>
                    {children}
            </Collapse>
        </div>
    )
}