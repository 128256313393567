import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { isNullOrUndefined } from 'util';
import { useForm, Form } from '../common/useForm.js'
import Companies from '../Companies.tsx';
import Controls from '../controls/Controls';

/**
 * Called from Users.js, which is called from Header.js
 * Displays the user profile information and performs validation on necessary fields.
 * @param {any} props
 */

export default function UserForm(props) {
    const { edit, user } = props;
    const initialValues =
        {
            id: user.userName,
            userName: user.userName,
            fullName: user.fullName,
            email: user.email,
            phone: isNullOrUndefined(user.phone) ? '' : user.phone,
            company: user.company,
            subscribeToEmail: user.subscribeToEmail,
            companyEmailList: isNullOrUndefined(user.companyEmailList) ? [] : user.companyEmailList
        }

    const refreshTimerOptions = [
        { id: 2, title: '2 Minutes (Default)' },
        { id: 5, title: '5 Minutes' },
        { id: 10, title: '10 Minutes' },
        { id: 'never', title: 'Never (Disable)' }]

    const [refreshTimer, setRefreshTimer] = useState(localStorage.getItem('refreshTimer') === null ? refreshTimerOptions[0].id : localStorage.getItem('refreshTimer'))

    useEffect(() => {
        if (!isNullOrUndefined(user))
            setValues({
                ...initialValues
            })
    }, [user])

    const validate = (fieldValues = values) => {

        let temp = {...errors}
        if ('fullname' in fieldValues)
            temp.fullname = fieldValues.fullname ? '' : 'This field is required.'
        if ('email' in fieldValues)
            temp.email = (/^.+@.+..+/).test(fieldValues.email) ? '' : 'Email Address is not valid.'
        if ('phone' in fieldValues)
            temp.phone = (isNullOrUndefined(fieldValues.phone) || fieldValues.phone.length > 9 || fieldValues.phone.length === 0) ? '' : 'Phone Number is not valid.'

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === '')
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleChange,
        resetForm
    } = useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault();
        if (validate()) {
            edit(values, resetForm);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Controls.Input name='userName'
                    label='Username'
                    disabled={true}
                    value={values.userName}
                    onChange={handleChange}
                />
                <Controls.Input name='fullName'
                    required
                    label='Full Name'
                    value={values.fullName}
                    onChange={handleChange}
                    error={errors.fullName}
                />
                <Controls.Input
                    required
                    label='Email Address'
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                />
                <Controls.Input
                    label='Phone Number'
                    name='phone'
                    value={values.phone ?? ''}
                    onChange={handleChange}
                    error={errors.phone}
                />
                <Controls.Input
                    label='Company'
                    name='company'
                    disabled={true}
                    value={values.company}
                />
                <Controls.Checkbox
                    label='Subscribe to Email'
                    name='subscribeToEmail'
                    defaultValue={!!values.subscribeToEmail}
                    value={values.subscribeToEmail}
                    onChange={handleChange}
                />
                {values.subscribeToEmail ?
                    <Companies name='companyEmailList'
                        label='Subscribe to Companies'
                        value={values.companyEmailList}
                        onChange={handleChange}
                        multiple
                    /> : null
                }
                <Controls.Select
                    label='Refresh Timer'
                    name='refreshTimer'
                    options={refreshTimerOptions}
                    onChange={(e) => {setRefreshTimer(e.target.value); }}
                    value={refreshTimer}
                    title='Adjust refresh timer for tabs in ICCPST.'
                />
                <div>
                    <Controls.Button
                        variant='contained'
                        color='primary'
                        onClick={(e) => { handleSubmit(e); localStorage.setItem('refreshTimer',refreshTimer); }}
                        text='Submit'
                        title='Update user information.'
                    />
                    <Controls.Button
                        variant='contained'
                        color='default'
                        onClick={(e) => { resetForm(e); setRefreshTimer(refreshTimerOptions[0].id) }}
                        text='Reset'
                        title='Undo changes to user.'
                    />
                </div>
            </Grid>
        </Form>
    )
}
