import React from 'react';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel } from '@mui/material'

export default function Checkbox(props)
{
    const { name, label, value, onChange, ...rest } = props;

    const convertToDefaultEventParams = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl>
            <FormControlLabel
                control={<MuiCheckbox
                    name={name}
                    color='primary'
                    checked={value}
                    onChange={e => onChange(convertToDefaultEventParams(name, e.target.checked))}
                    {...rest}
                />}
                label={label}
            />
        </FormControl>)
}