import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as Login from './Login';

/**
 * This ts file handles all API calls to/from CompanyController.cs
 * */

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CompanyState {
    data: Company[];
    isLoading: boolean;
    isLoaded: boolean;
}

export interface Company {
    id: string;
    interconnect: string;
    remoteName: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDataAction {
    type: 'COMPANIES_REQUEST_DATA';
}

interface ReceiveDataAction {
    type: 'COMPANIES_RECEIVE_DATA';
    companies: Company[];
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDataAction | ReceiveDataAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// All of the below fetch API calls go to the ModelPointController.cs file
export const actionCreators = {
    requestData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let user = Login.actionCreators.getUser();
        if (appState && appState.companies && !appState.companies.isLoaded && !appState.companies.isLoading) {
            fetch(`api/company/get`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        UserName: user.userName
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
                .then(response => response.json() as Promise<Company[]>)
                .then(data => {
                    dispatch({ type: 'COMPANIES_RECEIVE_DATA', companies: data });
                });

            dispatch({ type: 'COMPANIES_REQUEST_DATA' });
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CompanyState = { isLoading: false, isLoaded: false, data: [] };

export const reducer: Reducer<CompanyState> = (state: CompanyState | undefined, incomingAction: Action): CompanyState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'COMPANIES_REQUEST_DATA':
            return {
                ...state,
                isLoading: true
            };
        case 'COMPANIES_RECEIVE_DATA':
            return {
                data: action.companies,
                isLoading: false,
                isLoaded: true
            };
    }
    return state;
};