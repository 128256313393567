import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as Login from './Login'
import Notify from '../components/common/Notify';

/**
 * This ts file handles all API calls to/from PermissionsController.cs
 * It also includes an API call to SubmissionController.cs for creating changes
 * */

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RemotePermissionState {
    data: RemotePermission[];
    filterLists: [];
    isLoading: boolean;
    isLoaded: boolean;
}

export interface RemotePermission {
    dataItem: string;
    owningRemoteId: string;
    owningRemoteName: string;
    readingRemoteId: string;
    readingRemoteName: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDataAction {
    type: 'REMOTEPERMISSIONS_REQUEST_DATA';
}

interface ReceiveDataAction {
    type: 'REMOTEPERMISSIONS_RECEIVE_DATA';
    remotePermissions: RemotePermission[];
    filterLists: [];
}

interface CreateChangeAction {
    type: 'REMOTEPERMISSIONS_CREATE_CHANGE';
}

interface RefreshDataAction {
    type: 'REMOTEPERMISSIONS_REFRESH_DATA';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDataAction | ReceiveDataAction | CreateChangeAction | RefreshDataAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// All of the below fetch API calls go to the ModelPointController.cs file
export const actionCreators = {
    requestData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let user = Login.actionCreators.getUser()

        if (appState && appState.remotePermissions && !appState.remotePermissions.isLoaded && !appState.remotePermissions.isLoading && user !== undefined) {
            fetch(`api/permissions/get`, {
                method: 'POST',
                body: JSON.stringify({
                    OwningRemoteId: user.company
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())// as Promise<RemotePermission[]>)
                            .then(data => {
                                dispatch({ type: 'REMOTEPERMISSIONS_RECEIVE_DATA', remotePermissions: data.dtos, filterLists: data.filterLists });
                                if (data.dtos.length === 0)
                                    Notify({ severity: 'info', message: 'No data available.' });
                            });
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'REMOTEPERMISSIONS_REQUEST_DATA' });
                                Notify({ severity: 'error', message: data.message });
                            })
                    }
                })
            dispatch({ type: 'REMOTEPERMISSIONS_REQUEST_DATA' });
            Notify({ severity: 'info', message: 'Loading data' });
        }
    },
    refreshData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.remotePermissions) {
            Notify({ severity: 'info', message: 'Refreshing data' });
            dispatch({ type: 'REMOTEPERMISSIONS_REFRESH_DATA' });
        }
    },
    createChange: (dtos: [], clearSelection: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.dataPoints && dtos !== undefined) {
            fetch(`api/change/create`,
                {
                    method: 'POST',
                    body: JSON.stringify(dtos),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'REMOTEPERMISSIONS_CREATE_CHANGE' });
                                //localStorage.setItem('reloadData', JSON.stringify('current,pending'));
                                localStorage.setItem('reloadData', JSON.stringify('true'));
                                Notify({ severity: 'success', message: data.message })
                                clearSelection();
                            });
                    }
                    else {
                        (response.json())
                            .then(data => {
                                dispatch({ type: 'REMOTEPERMISSIONS_CREATE_CHANGE' });
                                Notify({ severity: 'error', message: data.message })
                            });
                    }
                })
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: RemotePermissionState = { isLoading: false, isLoaded: false, data: [], filterLists: [] };

export const reducer: Reducer<RemotePermissionState> = (state: RemotePermissionState | undefined, incomingAction: Action): RemotePermissionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REMOTEPERMISSIONS_REQUEST_DATA':
            return {
                ...state,
                isLoading: true
            };
        case 'REMOTEPERMISSIONS_RECEIVE_DATA':
            return {
                data: action.remotePermissions,
                filterLists: action.filterLists,
                isLoading: false,
                isLoaded: true
            };
        case 'REMOTEPERMISSIONS_REFRESH_DATA':
            return {
                data: [],
                filterLists: [],
                isLoaded: false,
                isLoading: false
            }
    }
    return state;
};