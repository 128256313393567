import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as RemotePermissionsStore from '../store/RemotePermissions';
import RemotePermissionsPage from './RemotePermissions/RemotePermissions.js';

/**
 * Receives props/state from RemotePermissions.ts
 * */

type RemotePermissionsProps =
    RemotePermissionsStore.RemotePermissionState
    & typeof RemotePermissionsStore.actionCreators

class RemotePermissions extends React.PureComponent<RemotePermissionsProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestData();
    }

    public render() {

        return (
            <React.Fragment>
                <RemotePermissionsPage {...this.props} from='remotePermissions' />
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.remotePermissions,
    RemotePermissionsStore.actionCreators
)(RemotePermissions as any);
