import * as Login from './Login';
import * as DataPoints from './DataPoints';
import * as Submissions from './Submissions';
import * as Companies from './Companies';
import * as RemotePermissions from './RemotePermissions';
import * as Scada from './Scada';

// The top-level state object
export interface ApplicationState {
    login: Login.LoginState | undefined;
    dataPoints: DataPoints.DataPointState | undefined;
    submissions: Submissions.SubmissionState | undefined;
    companies: Companies.CompanyState | undefined;
    remotePermissions: RemotePermissions.RemotePermissionState | undefined;
    scada: Scada.ScadaState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: Login.reducer,
    dataPoints: DataPoints.reducer,
    submissions: Submissions.reducer,
    companies: Companies.reducer,
    remotePermissions: RemotePermissions.reducer,
    scada: Scada.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
